<template>
  <header class="base-navigation">
    <a tabindex="1" class="logo-container" href="https://ourforest.io/">
      <img :src="require('@/assets/ourforest-logo.svg')" alt="OurForest logo">
    </a>
    <slot v-if="$slots.default"></slot>
    <div v-else class="base-navigation__links">
      <a @click="logout()" class="base-link subdued">Log out</a>
    </div>
  </header>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  methods: {
    logout() {
      this.$store.commit('logoutUser');
      this.$router.push('login')
    }
  },
  mounted() {
    window.addEventListener('scroll', (e: any) => {
      if (window.scrollY > 20) {
        document.querySelector('.logo-container img')?.classList.add('small');
      }
      if (window.scrollY < 20) {
        document.querySelector('.logo-container img')?.classList.remove('small');
      }
    })
  }
})

export default class BaseNavigation extends Vue {}
</script>

<style lang="scss">
@import '@/design';

.base-navigation {
  display: flex;
  padding: 24px 80px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
}
.logo-container img {
  top: -3px;
  position: relative;
  max-width: 160px;
  transition: all linear .1s
}
.logo-container img.small {
  max-width: 120px;
}
.base-navigation__links {
  display: flex;
  gap: 32px;
}

@media(max-width: 1024px) {
  .base-navigation {
    padding: 20px;
  }
  .logo-container img {
    max-width: 120px;
  }
}
</style>