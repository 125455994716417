
import { Options, Vue } from 'vue-class-component';

@Options({
  methods: {
    logout() {
      this.$store.commit('logoutUser');
      this.$router.push('login')
    }
  },
  mounted() {
    window.addEventListener('scroll', (e: any) => {
      if (window.scrollY > 20) {
        document.querySelector('.logo-container img')?.classList.add('small');
      }
      if (window.scrollY < 20) {
        document.querySelector('.logo-container img')?.classList.remove('small');
      }
    })
  }
})

export default class BaseNavigation extends Vue {}
