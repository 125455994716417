
import { Options, Vue } from 'vue-class-component';
import BaseNavigation from '@/components/BaseNavigation.vue';
import { commaSeparatedNumber, toFixedIfNecessary } from '@/helpers';
import projects from '@/projectsSupported.json';
import { getState } from '@/store'

@Options({
  title: 'Dashboard',
  components: { BaseNavigation },
  props: {
    navigatedFromCheckout: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      showBetaBanner: true,
      projects: projects
    }
  },
  methods: {
    open(href: string) {
      window.open(href);
    },
    commaSeparatedNumber: commaSeparatedNumber
  },
  computed: {
    getMonthsSubscribedMetric() {
      if (this.$store.state.membership) {
        const number = this.$store.state.membership.months_subscribed || 0;
        if (number >= 24) return `${toFixedIfNecessary(String(Number(number) / 12), 1)} years`;
        if (number === 1) return `${number} month`;
        else return `${number} months`;
      }
    },
    getMonthsSubscribedSubtitle() {
      if (this.$store.state.membership) {
        const name = this.$store.state.membership.name;
        if (name === 'Carbon Neutral') return 'climate neutral';
        else return 'climate positive';
      }
    },
    getLandProtectedMetric() {
      const landFromPlanting = Number(this.$store.state.treesPlanted) * 3.2;
      const landFromProtecting = Number(this.$store.state.carbonOffset) * 22.4;
      return commaSeparatedNumber(landFromPlanting + landFromProtecting, 0);
    },
    getWorkhoursCreatedMetric() {
      const workhoursFromPlanting = Number(this.$store.state.treesPlanted) * 2.4;
      const workhoursFromProtecting = Number(this.$store.state.carbonOffset) * 33;
      return commaSeparatedNumber(workhoursFromPlanting + workhoursFromProtecting, 0);
    },
    getCarbonOffset() {
      let carbon = this.$store.state.carbonOffset;
      const selectedPlanSpecs = getState('selectedPlanSpecs', 'sessionStorage');
      if (selectedPlanSpecs && this.navigatedFromCheckout && !this.hasStripeLoadedSubscription) {
        carbon += toFixedIfNecessary(selectedPlanSpecs.carbon_removed, 2);
      }
      return carbon;
    },
    getTreesPlanted() {
      let trees = this.$store.state.treesPlanted;
      const selectedPlanSpecs = getState('selectedPlanSpecs', 'sessionStorage');
      if (selectedPlanSpecs && this.navigatedFromCheckout && !this.hasStripeLoadedSubscription) {
        trees += Number(selectedPlanSpecs.trees_planted);
      }
      return trees;
    },
    hasStripeLoadedSubscription() {
      return this.$store.state.carbonOffset !== 0;
    },
  }
})

export default class Dashboard extends Vue {}
