<template>
  <BaseNavigation></BaseNavigation>
  <div class="dashboard-view">
    <div class="dashboard-view-container">
      <div v-if="showBetaBanner" class="base-banner">
        <div class="base-banner-close" @click="showBetaBanner = false">Close x</div>
        <p>
          Welcome to OurForest Premium! 👋 You're making a real impact on our planet, and we're so glad to have you. We're still in beta, but we're continually shipping improvements to this page so check back often to see new ways of visualizing your impact.
        </p>
      </div>
      <div class="dashboard-main-impact-container">
        <div class="main-impact climate-positive">
          <div class="image-container gradient-animation">
            <img src="@/assets/earth-badge.png" alt="climate positivity icon">
          </div>
          <h3>{{ getMonthsSubscribedMetric }}</h3>
          <h5>{{ getMonthsSubscribedSubtitle }}</h5>
        </div>
        <div class="main-impact carbon-reduced">
          <div class="image-container gradient-animation">
            <img src="@/assets/cloud-badge.png" alt="carbon reduced icon">
          </div>
          <h3>{{ getCarbonOffset }} tonnes</h3>
          <h5>of carbon reduction</h5>
        </div>
        <div class="main-impact trees-planted">
          <div class="image-container gradient-animation">
            <img src="@/assets/trees-badge.png" alt="forest icon">
          </div>
          <h3>{{ getTreesPlanted }} trees</h3>
          <h5>in your forest</h5>
        </div>
      </div>
      <div class="dashboard-section impact-visualised">
        <h2 class="dashboard-title">Your impact <span class="highlight">visualized</span>.</h2>
        <h3 class="dashboard-subtitle">Your funding directly impacts atmospheric CO2 levels, protects animal habitats, and provides jobs for impoverished communities.</h3>
        <div class="impact-visualised-container">
          <div class="visual-column carbon">
            <div class="visual-text">
              <h1>{{ Math.floor(Number($store.state.carbonOffset) / 1.3) }}</h1>
              <h3>long haul flights</h3>
            </div>
          </div>
          <div class="visual-column biodiversity">
            <div class="visual-text">
              <h1>{{ getLandProtectedMetric }}</h1>
              <h3>metres<sup>2</sup> land protected</h3>
            </div>
          </div>
          <div class="visual-column jobs">
            <div class="visual-text">
              <h1>{{ getWorkhoursCreatedMetric }}</h1>
              <h3>work hours created</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard-section projects-supported">
        <h2 class="dashboard-title">Global reforestation projects you've helped <span class="highlight">support.</span></h2>
        <h3 class="dashboard-subtitle">We work with carefully selected organisations focused on delivering the biggest impact.</h3>
        <div class="projects-supported-container">
          <div v-for="project in projects" :key="project.key" class="project-card">
            <div class="project-card-header" :class="project.key" :style="{ backgroundImage: `url(${require(`@/assets/${project.img}`)})` }"></div>
            <div class="project-card-body">
              <h4>{{ project.description }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard-section footer-container">
        <h5>© {{ new Date().getFullYear() }} OurForest. All rights reserved. Contact us at <span class="base-link">support@ourforest.io</span> with any questions you may have.</h5>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import BaseNavigation from '@/components/BaseNavigation.vue';
import { commaSeparatedNumber, toFixedIfNecessary } from '@/helpers';
import projects from '@/projectsSupported.json';
import { getState } from '@/store'

@Options({
  title: 'Dashboard',
  components: { BaseNavigation },
  props: {
    navigatedFromCheckout: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      showBetaBanner: true,
      projects: projects
    }
  },
  methods: {
    open(href: string) {
      window.open(href);
    },
    commaSeparatedNumber: commaSeparatedNumber
  },
  computed: {
    getMonthsSubscribedMetric() {
      if (this.$store.state.membership) {
        const number = this.$store.state.membership.months_subscribed || 0;
        if (number >= 24) return `${toFixedIfNecessary(String(Number(number) / 12), 1)} years`;
        if (number === 1) return `${number} month`;
        else return `${number} months`;
      }
    },
    getMonthsSubscribedSubtitle() {
      if (this.$store.state.membership) {
        const name = this.$store.state.membership.name;
        if (name === 'Carbon Neutral') return 'climate neutral';
        else return 'climate positive';
      }
    },
    getLandProtectedMetric() {
      const landFromPlanting = Number(this.$store.state.treesPlanted) * 3.2;
      const landFromProtecting = Number(this.$store.state.carbonOffset) * 22.4;
      return commaSeparatedNumber(landFromPlanting + landFromProtecting, 0);
    },
    getWorkhoursCreatedMetric() {
      const workhoursFromPlanting = Number(this.$store.state.treesPlanted) * 2.4;
      const workhoursFromProtecting = Number(this.$store.state.carbonOffset) * 33;
      return commaSeparatedNumber(workhoursFromPlanting + workhoursFromProtecting, 0);
    },
    getCarbonOffset() {
      let carbon = this.$store.state.carbonOffset;
      const selectedPlanSpecs = getState('selectedPlanSpecs', 'sessionStorage');
      if (selectedPlanSpecs && this.navigatedFromCheckout && !this.hasStripeLoadedSubscription) {
        carbon += toFixedIfNecessary(selectedPlanSpecs.carbon_removed, 2);
      }
      return carbon;
    },
    getTreesPlanted() {
      let trees = this.$store.state.treesPlanted;
      const selectedPlanSpecs = getState('selectedPlanSpecs', 'sessionStorage');
      if (selectedPlanSpecs && this.navigatedFromCheckout && !this.hasStripeLoadedSubscription) {
        trees += Number(selectedPlanSpecs.trees_planted);
      }
      return trees;
    },
    hasStripeLoadedSubscription() {
      return this.$store.state.carbonOffset !== 0;
    },
  }
})

export default class Dashboard extends Vue {}
</script>

<style scoped lang="scss">
@import '@/design';
@import '@/design/gradientAnimation.scss';

.dashboard-view {
  background: url(~@/assets/dashboard-background.svg);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: bottom;
}
.dashboard-view-container {
  display: flex;
  flex-direction: column;
  max-width: 880px;
  margin: auto;
  padding: 40px 10px;
}
.dashboard-main-impact-container {
  display: flex;
  justify-content: space-between;
  justify-content: center;
  column-gap: 64px;
  row-gap: 32px;
  flex-wrap: wrap;
}
.main-impact {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image-container {
  display: flex;
  position: relative;
  border-radius: 100%;
  height: 200px;
  width: 200px;
  margin: 16px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  user-select: none;
  padding: 24px;
  box-sizing: border-box;
}
.image-container img {
  pointer-events: none;
  width: 100%;
}
.trees-planted .image-container {
  background: linear-gradient(-45deg, #81BEE1, #B5ECBE, #D5E2C5, #F0B57E);
  background-size: 400% 400%;
  img {
    max-width: 140px;
  }
}
.carbon-reduced .image-container {
  background: linear-gradient(-45deg, #C698DC, #DF95C2, #D49494, #E93939);
  background-size: 400% 400%;
  img {
    max-width: 132px;
  }
}
.climate-positive .image-container {
  background: linear-gradient(-45deg, #88CC68, #63B78E, #349CBD, #1389DF);
  background-size: 400% 400%;
  img {
    position: relative;
    max-width: 96px;
  }
}
.base-banner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  background: white;
  border: 4px dashed $gray-300;
  padding: 16px;
  border-radius: 12px;
  margin: 12px 0;
  margin-bottom: 48px;
  row-gap: 16px;
  p {
    flex: 1;
    margin: 0;
    max-width: 660px;
    min-width: 240px;
  }
  .base-banner-close {
    white-space: nowrap;
    font-size: 16px;
    font-weight: $bold;
    color: $gray-500;
    padding-left: 64px;
    margin-left: auto;
    cursor: pointer;
  }
}
.dashboard-section {
  margin-top: 120px;
  text-align: center;
}
.dashboard-title .highlight {
  z-index: 0;
  position: relative;
  &::before {
    content: '';
    z-index: -1;
    top: 20px;
    position: absolute;
    height: 40%;
    width: 100%;
    background: #F8CEE7;
  }
}
.dashboard-subtitle {
  font-weight: $regular;
}
.projects-supported-container {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin: 48px 0;
  justify-content: center;
}
.projects-supported-container .project-card {
  flex: 1;
  background: white;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 100%;
  min-height: 280px;
  min-width: 300px;
  max-width: 340px;
  position: relative;
  text-align: center;
  transition: transform ease 0.3s;
  overflow: hidden;
}
.project-card .project-card-header {
  width: 100%;
  height: 220px;
  box-sizing: border-box;
  background-size: cover;
}
.project-card .project-card-body {
  padding: 24px;
}
.impact-visualised-container {
  display: flex;
  flex: 1;
  margin: 48px 0;
  flex-wrap: wrap;
}
.impact-visualised-container .visual-column {
  flex: 1;
  height: 480px;
  min-width: 240px;
  transition: all ease-in-out 1s;
  background-size: 250%;
  display: flex;
  &.carbon {
    background-image: 
    linear-gradient(to bottom, rgb(0, 0, 0, 0), rgb(0, 0, 0, 0.2), rgb(0, 0, 0, 0.9)),
    url(~@/assets/aeroplane.jpeg);
    background-position-x: 80%;
  }
  &.biodiversity {
    background-image:
    linear-gradient(to bottom, rgb(0, 0, 0, 0), rgb(0, 0, 0, 0.2), rgb(0, 0, 0, 0.9)),
    url(~@/assets/biodiversity.jpg);
    background-position-x: 50%;
  }
  &.jobs {
    background-image:
    linear-gradient(to bottom, rgb(0, 0, 0, 0), rgb(0, 0, 0, 0.2), rgb(0, 0, 0, 0.9)),
    url(~@/assets/jobs.jpg);
    background-position-x: 30%;
  }
}
.impact-visualised-container .visual-column:hover {
  background-size: 255%;
}
.footer-container {
  margin-top: 240px;
  h5 {
    color: white;
  }
}
.impact-visualised-container .visual-text {
  text-align: left;
  padding: 24px;
  margin-top: auto;
  h1 {
    font-size: 56px;
    color: white;
    margin: unset;
  }
  h3 {
    font-weight: $semibold;
    color: white;
  }
}

@media (max-width: 480px) {
  .impact-visualised-container .visual-column {
    height: 90vw;
  }
}
</style>
